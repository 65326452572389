<template>
    <div id="User_roles" class="pl-1 m-0" style="min-height: 100vh; border-left: 4px solid white">
        <div class="row">
            <div class="col-sm-12 col-lg-6 py-3 py-lg-5">
                <p class="manrope-title"> 
                    Incidentes
                </p>
            </div>

            <div class="col-sm-12 col-lg-6 py-0 pb-3 pb-lg-0 py-lg-5  p-0 m-0">
                <div class="row p-0 m-0  justify-content-end align-items-end">
                    <div class="col-12 col-md-4 d-flex col-lg-3 p-0 px-3 px-md-3 px-lg-0 m-0 pl-2 pl-md-0 pr-2 mr-0 mr-lg-3 justify-content-end" v-if="session.id_rol == 1">
                        <li-select @change="filterElements" v-if="filter == 'vehicle'"
                            class="opensans-bold mr-2"
                            label="Vehiculo:"
                            variant="secondary"
                            :options="vehicle_options"
                            v-model="id_vehicle"
                            full>
                        </li-select>
                        <li-select @change="filterElements"
                            class="opensans-bold"
                            label="Filtrar por:"
                            variant="secondary"
                            :options="[{id: 'final_revision', label:'Revision final'},{id: 'vehicle', label: 'Por vehiculo'}, {id: 'all', label: 'Todos'}]"
                            v-model="filter"
                            full>
                        </li-select>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4 p-0 px-3 px-md-0 m-0 my-3 my-md-0 pl-2 pl-md-0 justify-content-end" v-if="session.id_rol == 2">
                        <button @click.stop="incident_modal = true" 
                            class="btn btn-white btn-pill w-100 opensans-bold"
                            type="button">
                            Añadir incidencia
                        </button>
                    </div>

                    <div class="col-12 col-md-4 col-lg-4 position-relative text-white">
                        <input @keyup.enter="filterElements"
                            class="custom_input bg-dark opensans-bold text-white pl-3"
                            type="text"
                            v-model="search_query"
                            placeholder="Buscar"
                            />
                        <img class="position-absolute"
                            style="width: 25px; height: 25px; right: 2rem; top: 0.5rem"
                            :src="lupa_icon" />
                    </div>
                </div>
                

            </div>

            <div class="col-12" v-if="loading && !fetch_error">
                <li-spinner size="sm"/>
            </div>

            <div class="col-12" v-if="!loading && fetch_error">
                <p>
                    Ha ocurrido un error cargando los datos, intente más tarde.
                </p>
            </div>

            <div class="col-12" v-if="empty_search && search_query">
                <p>
                    No existen elementos con esos parametros de búsqueda.
                </p>
            </div>

            <div class="col-12" v-if="!loading && no_incident">
                <p>
                    No hay incidencias de momento.
                </p>
            </div>
            
            <div class="col-12 font-table" v-if="!loading && !fetch_error && !empty_search && !no_incident">
                <li-table :data="incidents_data" 
                    :allow_headers="incidents_headers"
                    pagination_off>
                    <template v-slot:type="data">
                        {{ data.item.type }}
                    </template>
                    <template v-slot:damages_cost="data">
                        {{ data.item.damages_cost ? `$ ${formatAmount(data.item.damages_cost)}` : ''}}
                    </template>
                    <template v-slot:incident_status="data">
                        {{ data.item.incident_status == 'created' ? 'Creado' : data.item.incident_status == 'assigned' ? 'Asignado' : data.item.incident_status == 'added_part' ? 'Pieza añadida' : data.item.incident_status == 'for_approval' ? 'Esperando aprovación' : data.item.incident_status == 'final_revision' ?  'Revisión final' : data.item.incident_status == 'declined' ? 'Rechazado' :'Terminado'}}
                    </template>
                    <template v-slot:estimated_price="data">
                        {{ data.item.estimated_price ? `$ ${formatAmount(data.item.estimated_price, 2)}` : '' }}
                    </template>
                    <template v-slot:estimated_date="data">
                        {{ data.item.estimated_date ? new Date(data.item.estimated_date).toLocaleDateString() : '' }}
                    </template>
                    <template v-slot:details="data">
                        <button @click.stop="selectAction('details', data.item.id_incident)"
                            class="btn btn-outline-primary btn-pill p-1 px-2"
                            type="button">
                            detalles
                        </button>
                    </template>
                    <template v-slot:date_incident="data">
                        {{ new Date(data.item.date_incident).toLocaleDateString()}}
                    </template>
                    <template v-slot:actions="data">
                        <!-- session.rol_id == 1 admin, 2 supervisor, 3 responsible --->
                        <div class="d-flex flex-column">
                            <button @click.stop="selectAction('assign', data.item.id_incident)"
                                v-if="session.id_rol == 2 && data.item.incident_status == 'created'"
                                class="btn btn-outline-primary btn-pill px-3 p-1"
                                type="button">
                                Asignar
                            </button>
                            <button @click.stop="selectAction('add_part', data.item.id_incident)"
                                v-if="session.id_rol == 3"
                                class="btn btn-outline-primary btn-pill px-3 p-1 mt-2"
                                type="button">
                                Refacción
                            </button>
                            <button @click.stop="selectAction('review', data.item.id_incident)"
                                v-if="session.id_rol == 3"
                                class="btn btn-outline-success btn-pill px-3 p-1 mt-2"
                                type="button">
                                Completar
                            </button>
                            <button @click.stop="selectAction('final_review', data.item.id_incident)"
                                class="btn btn-outline-success btn-pill px-3 p-1 mt-2"
                                v-if="session.id_rol == 2"
                                type="button">
                                Completar
                            </button>
                            <button @click.stop="selectAction('finish', data.item.id_incident)"
                                class="btn btn-outline-success btn-pill px-3 p-1 mt-2"
                                v-if="session.id_rol == 1 && data.item.incident_status == 'final_revision' "
                                type="button">
                                Finalizar
                            </button>
                            <button @click.stop="selectAction('reject', data.item.id_incident)"
                                v-if="(session.id_rol == 2 || session.id_rol == 1) && (data.item.incident_status == 'final_revision' || data.item.incident_status == 'for_approval') "
                                class="btn btn-outline-danger btn-pill px-3 p-1 mt-2"
                                type="button">
                                Rechazar
                            </button>
                        </div>
                        
                    </template>
                </li-table>
            </div>
        </div>

        <!---- Paginator ----->

        <div class="col-12" v-if="!fetch_error && !empty_search && !no_incident && !loading">
            <div class="row justify-content-between">

                <div class="col-12 col-md-5 col-lg-4 d-flex justify-content-center justify-content-lg-start align-items-center px-0 mt-2 mt-md-0">
                    <span class="mr-3 opensans-bold">
                        Filas:
                    </span>
                    
                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="rows"
                        :disabled="page > 1"
                        class="form-control bg-secondary text-white border-0"
                        />
                    
                    <li-select @change="filterElements"
                        class="pl-1"
                        variant="secondary"
                        :options="[{id: '1', label: 'Antiguos primero'}, {id: '2', label: 'Recientes primero'}]"
                        v-model="order_asc">
                    </li-select>
                </div>

                <div class="col-12 col-md-6 mt-3 mt-md-0 d-flex justify-content-center justify-content-md-end">

                    <button @click.stop="prev"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="page <= 1">
                        <img :src="arrow_left" />
                    </button>

                    <input @keyup.enter="filterElements"
                        type="number"
                        style="width: 60px;"
                        v-model="page"
                        class="form-control bg-secondary text-white border-0 mx-4 opensans-bold"
                        />


                    <button @click.stop="next"
                        class="btn btn-pill btn-outline-primary"
                        type="button"
                        :disabled="last_page">
                        <img :src="arrow_left" style="transform: rotateY(180deg);" />
                    </button>
                </div>
            </div>
        </div>

        <!-- Modal-->

        <li-modal :show_modal="incident_modal" :max_width="1200" class="px-3">
                <div class="col-6 manrope-title">
                    Añadir incidente
                </div>

                <div class="col-12 col-md-6 d-flex justify-content-md-end">

                    <button @click.stop="createIncidence"
                        class="btn btn-pill ml-3"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                            Aplicar
                    </button>
                                
                    <button @click.stop="clearData"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                            cerrar
                    </button>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-select label="Tipo:"
                        hidden_option="Seleccione..."
                        :options="incident_types"
                        v-model="incident_type"
                        variant="secondary"
                        full>
                    </li-select>
                    <span class="text-danger"  v-if="errors.incident_type">
                        {{ errors.incident_type}}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-select label="Vehiculo:"
                        hidden_option="Seleccione..."
                        :options="vehicle_options"
                        v-model="id_vehicle"
                        variant="secondary"
                        full>
                    </li-select>
                    <span class="text-danger"  v-if="errors.id_vehicle">
                        {{ errors.id_vehicle}}
                    </span>
                </div>


                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Descripción:"
                        type="text"
                        v-model="description"
                        placeholder="Descripción del incidente"
                        full>
                    </li-input>

                    <span class="text-danger"  v-if="errors.description">
                        {{ errors.description }}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Fecha de la incidencia"
                        type="date"
                        v-model="date_init"
                        :min="currentDate"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.date_init">
                        {{ errors.date_init }}
                    </span>
                </div>
        </li-modal>

        <li-modal :show_modal="assign_incident_modal" :max_width="1200" class="px-3">
                <div class="col-6 manrope-title">
                    Asignar incidencia
                </div>

                <div class="col-12 col-md-6 d-flex justify-content-md-end">

                    <button @click.stop="assignIncident"
                        class="btn btn-pill ml-3"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                            Aplicar
                    </button>
                                
                    <button @click.stop="clearData"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                            cerrar
                    </button>
                </div>

                <div class="col-12  opensans-bold">
                    <li-select label="Responsable:"
                        hidden_option="Seleccione..."
                        :options="responsible_options"
                        v-model="id_user"
                        variant="secondary"
                        full>
                    </li-select>
                    <span class="text-danger"  v-if="errors.id_user">
                        {{ errors.id_user}}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Costo estimado:"
                        type="number"
                        v-model="estimated_cost"
                        placeholder="Costo Estimado"
                        full>
                    </li-input>

                    <span class="text-danger"  v-if="errors.estimated_cost">
                        {{ errors.estimated_cost }}
                    </span>
                </div>

                <div class="col-12 col-md-6 p-2 opensans-bold">
                    <li-input label="Fecha estimada"
                        type="date"
                        v-model="estimated_date"
                        :min="currentDate"
                        full>
                    </li-input>
                    <span class="text-danger" v-if="errors.estimated_date">
                        {{ errors.estimated_date }}
                    </span>
                </div>
        </li-modal>

        <li-modal :show_modal="action_modal" :max_width="750" class="px-3">
                <div class="col-12 text-center manrope-title">
                    
                    <p v-if="session.id_rol == 3">
                        ¿Marcar lista para revisión?
                    </p>
                    <p v-if="session.id_rol == 2 && action == 'mark'">
                        ¿Marcar lista para revisión final?
                    </p>
                    <p v-if="session.id_rol == 1 && action == 'mark'">
                        ¿Marcar incidencia como completada?
                    </p>
                    <p v-if="(session.id_rol == 2 || session.id_rol == 1) && action == 'reject'">
                        ¿Rechazar incidencia?
                    </p>
                </div>

                <div class="col-12 py-2" v-if="(session.id_rol == 1) && action == 'mark'">
                    <div class="col-12  p-2 opensans-bold">
                        <li-input label="Costo final"
                            type="number"
                            v-model="damages_cost"
                            full>
                        </li-input>
                        <span class="text-danger" v-if="errors.damages_cost">
                            {{ errors.damages_cost }}
                        </span>
                    </div>
                </div>

                <div class="col-12 py-2" v-if="(session.id_rol == 1 || session.id_rol == 2) && action == 'reject'">

                    <div class="row">
                        <div class="col-12 col-md-6  p-2 opensans-bold">
                            <li-input label="Fecha de entrega limite"
                                type="date"
                                v-model="limit_date"
                                :min="currentDate"
                                full>
                            </li-input>
                            <span class="text-danger" v-if="errors.limit_date">
                                {{ errors.limit_date }}
                            </span>
                        </div>

                        <div class="col-12 col-md-6 p-2 opensans-bold">
                            <li-input label="Motivo del rechazo:"
                                type="text"
                                placeholder="Motivo del rechazo:"
                                v-model="reject_reason"
                                full>
                            </li-input>
                            <span class="text-danger" v-if="errors.reject_reason">
                                {{ errors.reject_reason }}
                            </span>
                        </div>

                        <div class="col-12">
                            <hr class="border border-secondary w-100">
                        </div>

                        <div class="col-12 col-md-5">
                            <p>
                                Evidencia
                            </p>
                            <li-dropfile _id="insurance_file" v-if="!picture"
                                @charged="checkFile"/>

                            <div class="d-flex flex-column  py-2" v-if="picture">
                                <img class="img-fluid"
                                    :src="picture" />
                                    <button @click.stop="picture = null"
                                        class="btn btn-primary mt-2"
                                        type="button">
                                        Cambiar
                                    </button>
                            </div>
                            <span class="text-danger" v-if="errors.picture">
                                {{ errors.picture}}
                            </span>
                        </div>
                    </div>
                    
                </div>

                <div class="col-12 py-2" v-if="session.id_rol == 1">
                    <div class="col-12">
                        <hr class="border border-secondary w-100">
                        <p>
                            Refacciones utilizadas
                        </p>
                    </div>

                    <div class="row" v-for="(part, index) in used_parts_options" :key="`part-${index}`">
                        <div class="col-4 py-2">
                            <li-checkbox :label="part.name"
                                v-model="part.accepted"
                                theme="dark"
                                variant="info"
                                >
                            </li-checkbox>
                        </div>
                        <div class="col-8" v-if="!part.accepted">
                            <li-input label="Motivo de rechazo:"
                                type="text"
                                placeholder="Motivo de rechazo"
                                v-model="part.reason"
                                full>
                            </li-input>
                            <span class="text-danger" v-if="part.error">
                                {{ part.error}}
                            </span>
                        </div>
                    </div>
                </div>


                <div class="col-12  d-flex justify-content-center">

                    <button @click.stop="processAction('review')" v-if="session.id_rol == 3"
                        class="btn btn-pill ml-3"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                            Completar
                    </button>

                    <button @click.stop="processAction('final_review')" v-if="session.id_rol == 2 && action == 'mark'"
                        class="btn btn-pill ml-3"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                            Completar
                    </button>

                    <button @click.stop="processAction('finish')" v-if="session.id_rol == 1 && action == 'mark'"
                        class="btn btn-pill ml-3"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                            Completar
                    </button>

                    <button @click.stop="processAction('reject')" v-if="(session.id_rol == 1 || session.id_rol == 2) && action == 'reject'"
                        class="btn btn-pill ml-3"
                        style="height: 35px; padding: 0 20px; color: black; background-color: white">
                            Rechazar
                    </button>
                                
                    <button @click.stop="selected_id = null; action = null; action_modal = false"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                            cerrar
                    </button>
                </div>


        </li-modal>

        <li-modal :show_modal="details_modal" :max_width="900" class="px-3">

                <div class="col-12 col-md-6 py-2">
                    <h4>
                        Detalles
                    </h4>
                </div>
                
                <div class="col-12 col-md-6  d-flex justify-content-center py-2">

                    <button @click.stop="switch_historial = !switch_historial"
                        class="btn btn-pill btn-primary ml-3"
                        style="height: 40px; padding: 0px 25px;">
                            Historial
                    </button>

                    <button @click.stop="details_modal = false; switch_historial = false"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                            cerrar
                    </button>
                </div>

                <div class="col-12 p-0 m-0" v-if="!switch_historial">
                    <div class="row p-0 m-0">

                        <div class="col-6">
                            <p style="margin: 0px;;">
                                Conductor:
                            </p>
                            <span class="pl-3">
                                {{ incident_details.driver_name }}
                            </span>
                            <hr style="border: 1px solid #008c9c;" class="m-0">
                        </div>

                        <div class="col-6">
                            <p style="margin: 0px;">
                                Tipo de incidencia:
                            </p>
                            <span class="pl-3">
                                {{ incident_details.type == 'mishap' ? 'Siniestro' : 'Administrativo' }}
                            </span>
                            <hr style="border: 1px solid #008c9c;" class="m-0">
                        </div>

                        <div class="col-6">
                            <p style="margin: 0px;">
                                Placa:
                            </p>
                            <span class="pl-3">
                                {{ incident_details.plate }}
                            </span>
                            <hr style="border: 1px solid #008c9c;" class="m-0">
                        </div>

                        <div class="col-6">
                            <p style="margin: 0px;">
                                Modelo:
                            </p>
                            <span class="pl-3">
                                {{ incident_details.model_name }}
                            </span>
                            <hr style="border: 1px solid #008c9c;" class="m-0">
                        </div>

                        <div class="col-6">
                            <p style="margin: 0px;">
                                Responsable:
                            </p>
                            <span class="pl-3">
                                {{ incident_details.user_responsible_name }}
                            </span>
                            <hr style="border: 1px solid #008c9c;" class="m-0">
                        </div>

                        <div class="col-6">
                            <p style="margin: 0px;">
                                Estatus:
                            </p>
                            <span class="pl-3">
                                {{ incident_details.incident_status == 'created' ? 'Creada' : incident_details.incident_status == 'assigned' ? 'Asignada' : incident_details.incident_status == 'valued' ? 'Valorada' : incident_details.incident_status == 'for_approval' ? 'Esperando aprovación' : incident_details.incident_status == 'final_revision' ? 'Revision final' : 'Terminada'}}
                            </span>
                            <hr style="border: 1px solid #008c9c;" class="m-0">
                        </div>

                        <div class="col-6">
                            <p style="margin: 0px;">
                                Costo Estimado:
                            </p>
                            <span class="pl-3">
                                 {{ incident_details.estimated_price ? `$ ${formatAmount(incident_details.estimated_price, 2)}` : 'No valorado'}}
                            </span>
                            <hr style="border: 1px solid #008c9c;" class="m-0">
                        </div>

                        <div class="col-6">
                            <p style="margin: 0px;">
                                Entrega Estimada:
                            </p>
                            <span class="pl-3">
                                 {{ incident_details.estimated_date ? new Date(incident_details.estimated_date).toLocaleDateString() : 'No valorado'}}
                            </span>
                            <hr style="border: 1px solid #008c9c;" class="m-0">
                        </div>

                        <div class="col-6">
                            <p style="margin: 0px;">
                                Dias transcurridos:
                            </p>
                            <span class="pl-3">
                                 {{ incident_details.elapsed_days}}
                            </span>
                            <hr style="border: 1px solid #008c9c;" class="m-0">
                        </div>

                        <div class="col-6">
                            <p style="margin: 0px;">
                                Descripción:
                            </p>
                            <span class="pl-3">
                                 {{ incident_details.description ? incident_details.description : 'Sin descripción' }}
                            </span>
                            <hr style="border: 1px solid #008c9c;" class="m-0">
                        </div>

                    </div>
                </div>

                <div class="col-12" v-if="switch_historial">
                    <div class="row border-primary py-2 my-2" v-for="(step, index) in incident_details.steps" :key="index"
                        style="border-radius: 10px;">

                        <div class="col-12" v-if="step.incident_status == 'created'">
                            <div class="row">
                                <div class="col-6 opensans-bold">
                                    <p class="m-0">
                                        Creada
                                    </p>
                                </div>
                                <div class="col-6">
                                    <span class="opensans-bold">
                                        Fecha: 
                                    </span>
                                    {{ new Date(step.creation).toLocaleDateString()}}
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-12" v-if="step.incident_status == 'assigned'">
                            <div class="row">
                                <div class="col-6 opensans-bold">
                                    <p class="m-0">
                                        Asignada
                                    </p>
                                </div>
                                <div class="col-6">
                                    <span class="opensans-bold">
                                        Fecha: 
                                    </span>
                                    {{ new Date(step.creation).toLocaleDateString()}}
                                </div>
                                <div class="col-12">
                                    <hr class="border border-secondary w-100">
                                </div>
                                <div class="col-12">
                                    <span class="opensans-bold">
                                        Responsable: 
                                    </span> 
                                    <br>
                                     {{ step.user_responsible_name }}
                                     <hr class="border border-secondary w-100 m-0 mb-2">
                                </div>
                                <div class="col-6">
                                    <span class="opensans-bold">
                                        Costo estimado: 
                                    </span> 
                                    <br>
                                    $ {{ formatAmount(step.estimated_price, 2)}}
                                    <hr class="border border-secondary w-100 m-0">
                                </div>
                                <div class="col-6">
                                    <span class="opensans-bold">
                                        Fecha Estimada: 
                                    </span>
                                    <br>
                                    {{ new Date(step.estimated_date).toLocaleDateString()}}
                                    <hr class="border border-secondary w-100 m-0">
                                </div>
                            </div>
                        </div>

                        <div class="col-12" v-if="step.incident_status == 'added_part'">
                            <div class="row">
                                <div class="col-6 opensans-bold">
                                    <p class="m-0">
                                        Parte añadida
                                    </p>
                                </div>
                                <div class="col-6">
                                    <span class="opensans-bold">
                                        Fecha: 
                                    </span>
                                    {{ new Date(step.creation).toLocaleDateString()}}
                                </div>
                                <div class="col-12">
                                    <span class="opensans-bold">
                                        Refacción: 
                                    </span>
                                    <br>
                                    {{ step.part_inventory_name}}
                                    <hr class="border border-secondary w-100">
                                </div>
                                <div class="col-12">
                                    <span class="opensans-bold">
                                        Refacción añadida por: 
                                    </span>
                                    <br>
                                    {{ step.user_responsible_name}}
                                    <hr class="border border-secondary w-100">
                                </div>
                            </div>
                        </div>
                        
                        
                        <div class="col-12" v-if="step.incident_status == 'for_approval'">
                            <div class="row">
                                <div class="col-6 opensans-bold">
                                    <p class="m-0">
                                        En Revisión
                                    </p>
                                </div>
                                <div class="col-6">
                                    <span class="opensans-bold">
                                        Fecha: 
                                    </span>
                                    {{ new Date(step.creation).toLocaleDateString()}}
                                </div>
                                <div class="col 12">
                                    <hr class="border border-secondary w-100 m-0 mb-2">
                                </div>
                                <div class="col-12">
                                    <span class="opensans-bold">
                                        Responsable: 
                                    </span> 
                                    <br>
                                     {{ step.user_responsible_name }}
                                     <hr class="border border-secondary w-100 m-0 mb-2">
                                </div>
                            </div>
                        </div>

                        <div class="col-12" v-if="step.incident_status == 'final_revision'">
                            <div class="row">
                                <div class="col-6 opensans-bold">
                                    <p class="m-0">
                                        Revision Final
                                    </p>
                                </div>
                                <div class="col-6">
                                    <span class="opensans-bold">
                                        Fecha: 
                                    </span>
                                    {{ new Date(step.creation).toLocaleDateString()}}
                                </div>
                            </div>
                        </div>

                        <div class="col-12" v-if="step.incident_status == 'finished'">
                            <div class="row">
                                <div class="col-6 opensans-bold">
                                    <p class="m-0">
                                        Incidencia Cerrada
                                    </p>
                                </div>
                                <div class="col-6">
                                    <span class="opensans-bold">
                                        Fecha: 
                                    </span>
                                    {{ new Date(step.creation).toLocaleDateString()}}
                                </div>
                            </div>
                        </div>

                        <div class="col-12" v-if="step.incident_status == 'declined'">
                            <div class="row">
                                <div class="col-6 opensans-bold">
                                    <p class="m-0">
                                        Rechazado
                                    </p>
                                </div>
                                <div class="col-6">
                                    <span class="opensans-bold">
                                        Fecha: 
                                    </span>
                                    {{ new Date(step.creation).toLocaleDateString()}}
                                </div>
                                <div class="col 12">
                                    <hr class="border border-secondary w-100 m-0 mb-2">
                                </div>
                                <div class="col-12">
                                    <span class="opensans-bold">
                                        Rechazado por: 
                                    </span> 
                                    <br>
                                     {{ step.user_movement_name }}
                                     <hr class="border border-secondary w-100 m-0 mb-2">
                                </div>
                                <div class="col-12">
                                    <span class="opensans-bold">
                                        Responsable: 
                                    </span> 
                                    <br>
                                     {{ step.user_responsible_name }}
                                     <hr class="border border-secondary w-100 m-0 mb-2">
                                </div>
                                <div class="col-12">
                                    <span class="opensans-bold">
                                        Motivo: 
                                    </span> 
                                    <br>
                                     {{ step.reason }}
                                     <hr class="border border-secondary w-100 m-0 mb-2">
                                </div>
                                <div class="col-12">
                                    <p class="opensans">
                                        Evidencia
                                    </p>
                                    <img class="img-fluid" :src="step.picture" style="object-fit: scale-down;">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        </li-modal>

        <li-modal :show_modal="add_part_modal" :max_width="750" class="px-3">
                <div class="col-12 text-center manrope-title">
                    <p>
                        ¿Añadir Refacciones?
                    </p>
                </div>

                <div class="col-12 mt-2 mb-4">
                    <li-select label="Codición de la pieza:"
                        class="pl-1"
                        variant="secondary"
                        :options="available_parts"
                        full
                        v-model="id_part_inventory">
                    </li-select>
                    <span class="text-danger" v-if="errors.id_part_inventory">
                        {{ errors.id_part_inventory}}
                    </span>
                </div>


                <div class="col-12  d-flex justify-content-center">

                    <button @click.stop="addPart"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                            Guardar
                    </button>
                                
                    <button @click.stop="selected_id = null; action = null; add_part_modal = false"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                            cerrar
                    </button>
                </div>


        </li-modal>

        <li-incidence-form :modal_close="incidence_form" @close:modal="incidence_form = false" />
    </div>
</template>

<script>

    import lupa_icon from 'ASSETS/icons/lupa_icon.png'
    import arrow_left from 'ASSETS/icons/arrow_left.png'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                lupa_icon,
                arrow_left,
                loading: true,
                fetch_error: false,
                search_query: null,
                empty_search: false,
                filter: 'final_revision',
                incident_modal: false,
                assign_incident_modal: false,
                appraise_modal: false,
                action_modal: false,
                details_modal: false,
                add_part_modal: false,
                id_part_inventory: 0,
                action: null,
                incidents_headers: [
                    { key: 'driver_name', label: 'Conductor'},
                    { key: 'plate', label: 'Placa'},
                    { key: 'model_name', label: 'Modelo'},
                    { key: 'date_incident', label: 'Fecha'},
                    { key: 'elapsed_days', label: 'Dias'},
                    { key: 'user_responsible_name', label: 'Responsable'},
                    { key: 'damages_cost', label: 'Daños'},
                    { key: 'type', label: 'Tipo'},
                    { key: 'estimated_price', label: 'C. Estimado'},
                    { key: 'estimated_date', label: 'Entrega estimada'},
                    { key: 'incident_status', label: 'Estatus'},
                    { key: 'details'},
                    { key: 'actions', label:'Acciones'}
                ],
                incidents_data: [],
                responsible_options: [],
                vehicle_options: [],
                incident_details: {},
                used_parts_options: [],
                switch_historial: false,
                no_incident: false,
                incidence_form: false,
                id_vehicle: null,
                //creation
                description: null,
                incident_type: 0,
                date_init: null,
                damages_cost: null,
                //assign
                id_user: null,
                estimated_cost: null,
                estimated_date: null,
                //reject
                picture: null,
                reject_reason: null,
                limit_date: null,
                errors: {
                    description: null,
                    incident_type: null,
                    id_vehicle: null,
                    date_init: null,
                    limit_date: null,
                    id_user: null,
                    estimated_cost: null,
                    estimated_date: null,
                    damages_cost: null,
                    id_part_inventory: null
                },
                
                //pagination
                page: 1,
                rows: 10,
                order_asc:  '2'
            }
        },
        computed: {
            ...mapGetters({
                session: 'getSession',
                users: 'users/getUsers',
                rents: 'rentals/getRentals',
                incidents: 'incidents/getIncidents',
                incident: 'incidents/getIncident',
                last_page: 'incidents/getLastPage',
                total_pages: 'incidents/getTotalPages',
                vehicles: 'unit_administration/getUnits',
                incident_types: 'incidents/getIncidentsTypes',
                available_parts: 'incidents/getAvailableParts',
                used_parts: 'incidents/getUsedParts'
            })
        },
        methods: {
            //helpers
            async filterElements(){
                let search = ''
                if(this.search_query) {
                    search = this.search_query
                }

                if( this.total_pages !== null && this.total_pages < this.page) {
                    this.page = this.total_pages == 0 ? 1 : this.total_pages
                }

                let order = this.order_asc == '2' ? false : true;
                
                
                switch(this.filter) {
                    case 'final_revision':
                        await this.$store.dispatch('incidents/listIncidents', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order, all: false})
                        break;
                    case 'all':
                        await this.$store.dispatch('incidents/listIncidents', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order, all: true})
                        break;
                    case 'vehicle':
                        if(!this.id_vehicle) return
                        await this.$store.dispatch('incidents/listIncidents', { datatable: true, page: this.page, rows: this.rows, status: null, search: search, order_asc: order, all: true, id_vehicle: this.id_vehicle})
                        break;
                }

                if(this.incidents === null) {
                    this.empty_search = true
                    this.no_incident = true
                    return
                }

                else {
                    this.empty_search = false
                    this.no_incident = false
                }

                this.incidents_data = this.incidents.map((incident) => {
                    return {
                        ...incident,
                        details: '-',
                        actions: '-'
                    }
                })
            },
            clearData(){
                this.selected_id = null
                this.init_date = null
                this.description = null
                this.id_incident_type = '0'
                this.id_user = '0'
                this.estimated_cost = null
                this.estimated_date = null

                this.incident_modal = false
                this.assign_incident_modal = false
                this.appraise_modal = false
                this.complete_modal = false
                this.id_part_inventory = 0
                this.add_part_modal = false

                for(let error in this.errors) {
                    this.errors[error] = null
                }
            },
            checkFile(data) {
                this.picture = data.url
            },
            async selectAction(type, id) {
                switch(type){
                    case 'details':
                        await this.$store.dispatch('incidents/viewIncident', { id_incident: id})
                        this.incident_details = this.incident
                        this.details_modal = true
                        break;
                    case 'assign':
                        this.selected_id = id
                        this.assign_incident_modal = true
                        break;
                    case 'review':
                        this.selected_id = id
                        this.action = 'review'
                        this.action_modal = true
                        break;
                    case 'final_review':
                        this.selected_id = id
                        this.action = 'mark'
                        this.action_modal = true
                        break;
                    case 'finish':
                        this.selected_id = id
                        this.action = 'mark'
                        this.action_modal = true
                        break;
                    case 'add_part':
                        this.selected_id = id
                        await this.$store.dispatch('incidents/listAvaliableParts', { id_incident: this.selected_id})
                        this.add_part_modal = true
                        break;
                    case 'reject':
                        this.selected_id = id
                        this.action = 'reject'
                        await this.$store.dispatch('incidents/listUsedParts', { id_incident: this.selected_id})
                        this.used_parts_options = this.used_parts
                        this.action_modal = true
                        break;
                }
            },

            //incidences process
            async createIncidence(){
               
                this.errors.incident_type = null
                this.errors.description = null
                this.errors.date_init = null
                this.errors.id_vehicle = null

                let complete = true;

                if(_.isNull(this.description) || _.isEmpty(this.description)) {
                    complete = false;
                    this.errors.description = '*Campo obligatorio'
                }


                if(_.isNull(this.date_init) || _.isEmpty(this.date_init)) {
                    complete = false;
                    this.errors.date_init = '*Campo obligatorio'
                }

                if(_.isNull(this.incident_type) || _.isEmpty(this.incident_type)) {
                    complete = false;
                    this.errors.incident_type = '*Campo obligatorio'
                }

                if(_.isNull(this.id_vehicle) || _.isEmpty(this.id_vehicle)) {
                    complete = false;
                    this.errors.id_vehicle = '*Campo obligatorio'
                }

                if(complete){
                    
                    const payload = {
                        date_incident: this.date_init,
                        description: this.description,
                        id_incident_type: this.incident_type,
                        id_vehicle: this.id_vehicle
                    }

                    await this.$store.dispatch('incidents/addIncident', payload)
                    await this.filterElements()

                    this.incident_modal = false
                    this.date_init = null
                    this.incident_type = 1
                    this.description = null
                    this.id_vehicle = null
                }
            },
            async assignIncident(){

                this.errors.id_user = null
                this.errors.estimated_cost = null
                this.errors.estimated_date = null

                let complete = true;

                if(_.isNull(this.id_user) || _.isEmpty(this.id_user) || this.id_user == '0') {
                    complete = false;
                    this.errors.id_user = '*Campo obligatorio'
                }

                if(_.isNull(this.estimated_cost)) {
                    complete = false;
                    this.errors.estimated_cost = '*Campo obligatorio'
                }

                if(_.isNull(this.estimated_date)) {
                    complete = false;
                    this.errors.estimated_date = '*Campo obligatorio'
                }

                if(complete) {
                    const payload = {
                        id_incident: this.selected_id,
                        id_responsible: this.id_user,
                        estimated_price: this.estimated_cost,
                        estimated_date: this.estimated_date
                    }
                    
                    await this.$store.dispatch('incidents/assignResponsible', payload)

                    await this.filterElements()
                    
                    this.id_user = null
                    this.estimated_cost = null
                    this.estimated_date = null
                    this.selected_id = null
                    this.assign_incident_modal = false

                }
            },
            async processAction(action){
                switch(action) {
                    case 'review':
                        await this.$store.dispatch('incidents/requestApproval', {id_incident: this.selected_id})
                        await this.filterElements();
                        this.action_modal = false
                        this.selected_id = null
                        break;
                    case 'final_review':
                        await this.$store.dispatch('incidents/approveIncident', {id_incident: this.selected_id})
                        await this.filterElements()
                        this.action_modal = false
                        this.selected_id = null
                        break;
                    case 'finish':
                        this.errors.damages_cost = null

                        if(_.isNull(this.damages_cost) || _.isEmpty(this.damages_cost)) {
                            this.errors.damages_cost = '*Campo obligatorio'
                            return
                        }

                        await this.$store.dispatch('incidents/finishIncident', {id_incident: this.selected_id, damages_cost: this.damages_cost})
                        await this.filterElements();
                        this.action_modal = false
                        this.selected_id = null
                        break;
                    case 'reject':
                        this.errors.limit_date = null
                        this.errors.picture = null;
                        this.errors.reject_reason = null

                        let complete = true

                        if(_.isNull(this.limit_date) || _.isEmpty(this.limit_date)) {
                            this.errors.limit_date = '*Añada una fecha de limite'
                            complete = false
                        }

                        if(_.isNull(this.reject_reason) || _.isEmpty(this.reject_reason)) {
                            this.errors.reject_reason = '*Añada una fecha de limite'
                            complete = false
                        }

                        if(_.isNull(this.picture) || _.isEmpty(this.picture)) {
                            this.errors.reject_reason = '*Añada una fecha de limite'
                            complete = false
                        }

                        this.used_parts_options.forEach((part) => {
                            if(!part.accepted && !part.reason) {
                                part.error = '*Obligatorio'
                                complete = false
                            }
                        })

                        if(complete) {

                            let rejected_parts = []
                            this.used_parts_options.forEach((part) => {
                                if(!part.accepted) {
                                    rejected_parts.push({id_incident_step: part.id_incident_step, reason: part.reason })
                                }
                            })

                            const payload = {
                                id_incident: this.selected_id,
                                limit_date: this.limit_date,
                                picture: this.picture,
                                reason: this.reject_reason,
                                parts_declined: rejected_parts
                            }

                            await this.$store.dispatch('incidents/declineIncident', payload)
                            await this.filterElements()
                            this.reject_reason = null
                            this.limit_date = null
                            this.picture = null
                            this.action_modal = false
                            this.selected_id = null
                        }
                        break;
                }
            },
            async addPart() {
                this.errors['id_part_inventory'] = null

                let complete = true

                if(_.isNull(this.id_part_inventory) || this.id_part_inventory == 0) {
                    this.errors['id_part_inventory'] = '*Campo necesario';
                    complete = false
                }

                if(complete) {
                    let query = {
                        id_part_inventory: this.id_part_inventory,
                        id_incident: this.selected_id
                    }

                    await this.$store.dispatch('incidents/addPart', query)
                    this.clearData()
                    await this.filterElements()
                }
            },
            //Pagination
            prev(){
                this.page--;
                this.filterElements()
            },
            next(){
                this.page++;
                this.filterElements()
            }
        },
        async created() {
            
            try {
                //current session 1 = admin , 2 = supervisor, 3 = responsable

                //list of responsibles
                await this.$store.dispatch('users/listUsers', {datatable: false, id_rol: 3, status: 'active'})

                //list of incident types
                await this.$store.dispatch('incidents/listIncidentsTypes');
                
                this.responsible_options = this.users.map( user => {
                    return {
                        id: user.id_user,
                        label: user.full_name
                    }
                })

                //list of vehicles
                await this.$store.dispatch('unit_administration/listVehicles', {datatable: false, status: null})
                this.vehicle_options = this.vehicles.map( (vehicle) => {
                    return {
                        label: `${vehicle.plate || 'Sin placas'} ${vehicle.model_name}`,
                        id: vehicle.id_vehicle
                    }
                })

                let datatable = {
                    datatable: true,
                    rows: this.rows,
                    page: this.page,
                    all: false,
                    active: true,
                    order_asc: this.order_asc
                }

                //list of incidents
                await this.filterElements()

                this.loading = false
                this.fetch_error = false
            }
            catch(error) {
                this.loading = false
                this.fetch_error = true
                console.error(error)
            }
        }
    }
</script>

<style lang="scss" scoped>
    #User_roles {
        .custom_input {
            height: 40px;
            border: 1px solid white;
            border-radius: 30px;
            width: 100%;
            &::placeholder{
                padding-left: 15px;
                color: white;
            }
            &:focus{
                outline: none;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] { 
            -moz-appearance: textfield;
            appearance: textfield;
            margin: 0; 

        }
    }
</style>